let table;

toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": false,
    "progressBar": false,
    "positionClass": "toast-bottom-right",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
}

const sweetAlert2 = Swal.mixin({
    toast: false,
    position: 'center',
    showConfirmButton: true,
    timer: 3000
});

$(function () {
    $("a.menu-link").click(function () {
        const menuItem = $(this);
        const action = menuItem.data("action");
        $(".menu a.menu-link").removeClass('is-active');
        menuItem.addClass('is-active');
        switch (action) {
            case "newUser":
                $("#mainContent").load("/assets/php/templates/admin/newUser.php");
                break;
            case "shifts":
                $("#mainContent").load("/assets/php/templates/admin/shiftList.php");
                break;
            case "users":
            default:
                loadUserList();
                break;
        }
    });
});

function toggleTheme() {
    $("body").toggleClass('is-dark');
}

function exportShifts() {
    let doc = new jsPDF('l', 'pt');
    const elementHTML = $('#shiftTable').html();
    doc.fromHTML(elementHTML, 15, 15, {
        'width': 170,
    });

// Save the PDF
    doc.save('sample-document.pdf');
}

function login() {
    $.ajax({
        type: "POST",
        url: "/assets/php/ajax.php?action=login",
        data: $("#login").serialize(), // serializes the form's elements.
        success: function (data) {
            console.log(data)
            window.location.href = data;
        },
        error: function (data) {
            console.log(data)
            toastr.error(data.responseText);
        }
    });
    return false;
}

function newUser() {
    $.ajax({
        type: "POST",
        url: "/assets/php/ajax.php?action=newUser",
        data: $("#newUser").serialize(), // serializes the form's elements.
        success: function (data) {
            loadUserList();
            toastr.success("Aangemaakt");
        },
        error: function (data) {
            toastr.error(data.responseText);
        }
    });
    return false;
}

function deleteUser(personnelNumber) {
    $.ajax({
        type: "GET",
        url: "/assets/php/ajax.php?action=deleteUser&data=" + personnelNumber,
        success: function (data) {
            loadUserList();
            toastr.success("Verwijderd");
        },
        error: function (data) {
            toastr.error(data.responseText);
        }
    });
    return false;
}

function shiftUpdate(action) {
    $.ajax({
        type: "POST",
        url: "/assets/php/ajax.php?action=updateShift",
        data: $("#shiftUpdate").serialize() + '&action=' + encodeURI(action), // serializes the form's elements.
        success: function (data) {
            $("#shiftUpdate")[0].reset();
            sweetAlert2.fire({
                icon: "success",
                title: "Succes!",
                text: data,
            });
        },
        error: function (data) {
            sweetAlert2.fire({
                icon: "error",
                title: "Oeps...",
                text: data.responseText,
            });
        }
    });
    return false;
}

function loadUserList() {
    $("#mainContent").load("/assets/php/templates/admin/userList.php");
}
